import 'jquery';
// import 'suggestions-jquery';
import 'jquery-ui/ui/widgets/slider';
import 'jquery-ui-touch-punch/jquery.ui.touch-punch';
import 'jquery-form-styler';
import 'jquery-mask-plugin';
import 'bootstrap';
import 'slick-slider';
import '@fancyapps/fancybox';
import 'notifyjs-browser';
