function ajaxGetUpdate(url) {
    $.get(url, function (results) {
        const partners = $("div.part-item", results);
        const ulPages = $(".pagin-block", results);

        $(".part-list").html(partners);
        $(".pagin-block").html(ulPages);
    }, "html");
}

$(document).on('click', ".pagin-block a", function (e) {
    e.preventDefault();
    ajaxGetUpdate(this.href);
});