$(".renounce_form input[type='file']").bind('change', function () {
  const limit = 10;
  const limitMb = limit * 1024 * 1024;
  const inputField = this;
  if (inputField.files[0].size > limitMb) {
    $(this).val(null);
    $(".jq-file").notify(`Максимальный размер файла ${limit} Мб`, "warn");
  } else {
    $(".jq-file__name").html(inputField.files[0].name)
  }
});


function setFileInput() {
  $('.servises-about__form-file input[type="file"]').styler({
    fileBrowse: 'Прикрепить файл',
    filePlaceholder: 'Название файла.jpg',
    singleSelectzIndex: '999',
    onSelectOpened: function () {
      // к открытому селекту добавляется красная обводка
      $(this).css('box-shadow: 0px 15px 31px rgba(0, 0, 0, 0.1);');
    }
  });
}

$(() => {
  setFileInput()
});


$(() => {
  $('body').on('submit', '.js-ajax-form', function ajaxFormSubmit(ev) {
    ev.preventDefault();
    const $ajaxForm = $(this);
    const $ajaxFormWrapper = $ajaxForm.parent();

    let postData = new FormData();
    try {
      postData.append('file', $(this).find('input[type="file"]')[0].files[0]);
    } catch (e) {
    }

    const formData = $(this).serializeArray()
    for (let i = 0; i < formData.length; i += 1) {
      postData.append(formData[i].name, formData[i].value);
    }
    $.ajax({
      url: $ajaxForm.attr('action'),
      data: postData,
      processData: false,
      contentType: false,
      type: "POST",
      success: function (response) {
        setTimeout(() => {
          $.fancybox.close(true);
        }, 2000);
      },
      error: function (response) {
        const responseJson = response.responseJSON;
        $ajaxFormWrapper.html(responseJson.html);
        setFileInput()
      }
    })
  });
});
