$("#department_code").mask(
    '000-000'
);

$("#id_series").mask(
    '0000'
);

$("#id_number").mask(
    '000 000'
);

$("#card-year").mask(
  '00'
);

$("#card-month").mask(
  '00'
);

$("#card-number").mask(
  '0000 0000 0000 0000'
);

$("#card-cvv").mask(
  '000'
);

function suggestionsClickOutside() {
  $('.suggestList').remove()
}

function SuggestionClick(e) {
  e.stopPropagation();
  const target_id = e.data.target_id;
  // x+d
  if (target_id === 'department_code') {
    $("#department_code").val($(e.target).data('code'));
    $("#issued").val($(e.target).data('name'));
  } else if (target_id === 'pass_region') {
    $("#pass_region").val($(e.target).data('region_with_type'));
    $("#pass_city").val($(e.target).data('city'));
  }

  suggestionsClickOutside();
}

function showSuggestion(e) {
  const target_id = e.target.id;
  const input_data = $(e.target).val();
  $.ajax({
    url: '/dadata/suggest/',
    data: {
      target_id: target_id,
      input_data: input_data,
    },

    type: "POST",
    success: function (response) {
      const html = response.suggest.html;
      $('.suggestList').remove();
      if (html !== null) {
        $(html).insertAfter($(e.target).parent());
        // Если есть возможность сразу передать уже готовую дату, то передать её
        $('.suggestList .suggestItem').bind('click', { target_id: target_id }, SuggestionClick);
      }
    },
    error: function (response) {
      console.log(response)
    }
  })
}

$(document).click(function (e) {
  suggestionsClickOutside();
});

// // Хз что это и зачем оно тут
// $('.suggestItem').on('click', function (e) {
//
// });

$("#department_code").on('click keyup', function (e) {
  showSuggestion(e);
});

$("#pass_region").on('click keyup', function (e) {
  showSuggestion(e);
});