const PERCENT = 64
var amount = 30000
var term = 5

$(() => {
  $('.advantages__list').slick({
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: '<button class="prev-left"></button>',
    nextArrow: '<button class="prev-right"></button>',
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          variableWidth: true,
        }
      }
    ]
  });

  $('.steps__list').slick({
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: '<button class="prev-left"></button>',
    nextArrow: '<button class="prev-right"></button>',
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 576,
        settings: {
          //dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          variableWidth: true,
        }
      }
    ]
  });


  $("#range").slider({
    range: "min",
    max: 80000,
    min: 1000,
    value: 30000,
    step: 500,
    slide: function (e, ui) {
      $("#currentVal").val(ui.value);
      amount = ui.value
      var current_percent = PERCENT + (term - 5) - Math.round(amount / 5000)
      $("#currentVal-ver").html(`${current_percent}%`);
    }
  });
});

$(() => {

  $("#range-new").slider({
    range: "min",
    max: 30,
    min: 5,
    value: 1,
    slide: function (e, ui) {
      $("#currentVal-new").html(ui.value);
      term = ui.value
      var current_percent = PERCENT + (term - 5) - Math.round(amount / 5000)
      $("#currentVal-ver").html(`${current_percent}%`);
    }
  });
});

$(() => {
  
  $("#range-ver").slider({
    range: "min",
    max: 100,
    min: 1,
    value: 65,
    slide: function (e, ui) {
      $("#currentVal-ver").html(`${ui.value}%`);
    } 
  });
  $('#range-ver').find('.ui-slider-handle.ui-corner-all.ui-state-default').html('<span id="currentVal-ver">65%</span>')
});

$(() => {
  $(".mask_phone").mask('+7 999-999-99-99');
});

$('.burger').on('click', function() {
  $('.header-menu').toggleClass('d-flex')
});


$(() => {
  $("#currentVal").mask('00000');
  $("#currentVal").attr('step', $("#range").slider('option', "step"));
  $("#currentVal").attr('min', $("#range").slider('option', "min"));
  $("#currentVal").attr('max', $("#range").slider('option', "max"));
  $("#currentVal").val($("#range").slider('option', "value"));
});

$('#currentVal').on("change", function(e) {
  let cur_val = $(this).val(),
      step = $('#range').slider("option", "step"),
      min_val = $('#range').slider("option", "min"),
      max_val = $('#range').slider("option", "max");

  if(cur_val < min_val) {
    cur_val = min_val
  }

  cur_val = step * (Math.floor(cur_val / step) + (cur_val % step > 0 ? 1 : 0))

  if(cur_val > max_val) {
    cur_val = max_val
  }

  $('#range').slider("value", $(this).val(cur_val).val())
  amount = cur_val;
  var current_percent = PERCENT + (term - 5) - Math.round(amount / 5000);
  $("#currentVal-ver").html(`${current_percent}%`);
});

