const LOCAL_STORAGE_TIMESTAMP = 'local_timestamp';
const INIT_TIMER_MINUTES = 14;
const INIT_TIMER_SECONDS = 59;
const RESUME_AFTER_MIN = 24 * 60;

let timer_id;
let resumer_id ;

function tick() {
  const today = new Date().getTime();
  const local_timestamp = localStorage[LOCAL_STORAGE_TIMESTAMP];
  const elapsed = today - local_timestamp;
  const minutes = INIT_TIMER_MINUTES - Math.floor((elapsed % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = INIT_TIMER_SECONDS - Math.floor((elapsed % (1000 * 60)) / 1000);

  if (minutes >= 0) {
    setTimerValue(minutes, seconds);
  } else {
    clearInterval(timer_id);
    setTimerValue(0, 0);
    hideTimer();
    startResumer()
  }
}

function resumeTimer() {
  const local_timestamp = localStorage[LOCAL_STORAGE_TIMESTAMP];
  const today = new Date().getTime();

  if ((today - local_timestamp) / 60000 >= RESUME_AFTER_MIN) {
    localStorage.removeItem(LOCAL_STORAGE_TIMESTAMP);
    localStorage.setItem(LOCAL_STORAGE_TIMESTAMP, today);
    get_or_create_timestamp();
    showTimer();
    startTimer();
    clearInterval(resumer_id);
  }
}


function hideTimer() {
  const timerSelector = ".fast-time__inn";
  $(timerSelector).addClass('hidden');
  showExpiredBanner();
}

function showTimer() {
  const timerSelector = ".fast-time__inn";
  $(timerSelector).removeClass('hidden');
  hideExpiredBanner();
}

function showExpiredBanner(){
  const expiredBanner = '.fast-time__expired';
  $(expiredBanner).removeClass('hidden');
}

function hideExpiredBanner(){
  const expiredBanner = '.fast-time__expired';
  $(expiredBanner).addClass('hidden');
}

function get_or_create_timestamp() {
  if (localStorage[LOCAL_STORAGE_TIMESTAMP] === undefined) {
    const now = new Date().getTime();
    localStorage.setItem(LOCAL_STORAGE_TIMESTAMP, now);
  }
}

function prettifyTime(i) {
  if (i < 10) {
    i = "0" + i
  }
  return i;
}

function setTimerValue(min, sec) {
  // if (min < 15 && min >= 10) {
  //   setBackgroundTimerColor('green');
  // } else if (min < 10 && min >= 5) {
  //   setBackgroundTimerColor('orange');
  // } else if (min < 5 && min >= 0) {
  //   setBackgroundTimerColor('red');
  // }

  const timerWrapper = '.fast-time__inn .fast-time__clock ';
  $(timerWrapper + ".clock-min").html(`${prettifyTime(min)}`);
  $(timerWrapper + ".clock-sec").html(`${prettifyTime(sec)}`);
}

function setBackgroundTimerColor(color) {
  if (color == "#FFFFFF") {
    $('.fast-time__clock').css({'color': 'white'});
  } else {
    $('.fast-time__clock').css({'color': 'white'});
  }

  const colorRGB = hexToRgb(color);
  const timerSelector = ".fast-time__inn";
  $(timerSelector).css(
    {
      'background': `rgba(${colorRGB.r}, ${colorRGB.g}, ${colorRGB.b}, 1)`,
    })

}

function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

function startResumer(){
  resumeTimer();
  resumer_id = setInterval(resumeTimer, 5000);
}

function startTimer() {
  tick();
  timer_id = setInterval(tick, 1000);
}

$(() => {
  get_or_create_timestamp();
  startTimer();
})
